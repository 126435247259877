import {CBadge, CCol, CInput, CRow} from '@coreui/react';
import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import DataCard from '../../../components/DataCard/DataCard';
import DataRow from '../../../components/DataRow/DataRow';
import { useMutation } from 'react-query';
import CustomersService from '../../../services/CustomersService';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from '../../../components/Loader/Loader';
import { toast } from 'react-toastify';

const ClientBox = function ({
  city,
  customer_type_name,
  customer_type_pl,
  email,
  name,
  phone,
  surname,
  company_name,
  postal_code,
  street,
  flat_number,
  nip,
  employer_nip,
  google_validation,
  krs,
  id_card_number,
  enableEdit = false,
  refetch = () => {},
}) {
  const { id: customerId } = useParams();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name,
      surname,
      phone,
      city,
      postal_code,
      street,
      flat_number,
      email,
    },
  });

  const [isEditable, setIsEditable] = useState(false);
    const [representativeId, showRepresentativeId] = useState(false);

  const [editClient, { isLoading }] = useMutation(
    CustomersService.updateCustomer(customerId),
    {
      onSuccess: () => {
          refetch().then((newData) => {
              reset(newData.customer_data); // Reset the form with the updated data
          });
          toast.success('Zmiany zostały zapisane.');
          setIsEditable(false);
      },
      onError: (error) => {
          if(error.response.data.errors) {
              error.response.data.errors.map((error) => {
                  toast.error(error.message);
              });
          } else {
              toast.error('Nie udało się zapisać danych klienta.');
          }
      },
    }
  );

  const onSubmit = (values) => {
      editClient(values);
  };

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  return (
    <>
      <Loader show={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DataCard
          title="Dane klienta"
          HeaderButtons={
            enableEdit && (
              <>
                {isEditable ? (
                  <div className="d-flex">
                    <Button className="mr-2" type="submit">
                      Zapisz
                    </Button>
                    <Button onClick={toggleEdit}>Anuluj</Button>
                  </div>
                ) : (
                  <Button onClick={toggleEdit}>Edytuj</Button>
                )}
              </>
            )
          }
        >
          <CRow>
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="NIP wnioskodawcy" value={nip} />
            </CCol>
            {customer_type_name === 'LEGAL_PERSON' && (
                <CCol sm="12" xl="12">
                  <DataRow fullHeight label="KRS wnioskodawcy" value={krs} />
                </CCol>
            )}
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Forma prawna" value={customer_type_pl} />
            </CCol>
            {(customer_type_name === 'LEGAL_PERSON' ||
                customer_type_name === 'INDIVIDUAL_ENTREPRENEUR') && (
                <CCol sm="12" xl="12">
                  <DataRow fullHeight label="Nazwa" value={company_name} />
                </CCol>
            )}
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Imię"
                  value={
                    isEditable ? <CInput name="name" innerRef={register} /> : name
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Nazwisko"
                  value={
                    isEditable ? (
                        <CInput name="surname" innerRef={register} />
                    ) : (
                        surname
                    )
                  }
              />
            </CCol>
            {customer_type_name === 'LEGAL_PERSON' && (
                <CCol sm="12" xl="12">
                  <DataRow
                      label="Numer dowodu osobistego"
                      value={
                          <>
                              {id_card_number ? (
                                  <>
                                      {representativeId && id_card_number}
                                      {!representativeId && (
                                          <Button onClick={() => showRepresentativeId(true)}>Pokaż</Button>
                                      )}
                                  </>
                              ) : ('-')}
                          </>
                      }
                  />
                </CCol>
            )}
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Telefon"
                  value={
                    isEditable ? <CInput name="phone" innerRef={register} /> : phone
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Email"
                  value={
                    isEditable ? <CInput name="email" innerRef={register} /> : email
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Miasto"
                  value={
                    isEditable ? <CInput name="city" innerRef={register} /> : city
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Kod pocztowy"
                  value={
                    isEditable ? (
                        <CInput name="postal_code" innerRef={register} />
                    ) : (
                        postal_code
                    )
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Ulica"
                  value={
                    isEditable ? <CInput name="street" innerRef={register} /> : street
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Numer mieszkania"
                  value={
                    isEditable ? (
                        <CInput name="flat_number" innerRef={register} />
                    ) : flat_number === '' ? null : (
                        flat_number
                    )
                  }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                  fullHeight
                  label="Walidacja google"
                  value={
                    <>
                      {google_validation === true && (
                          <CBadge color={'success'} className="white-space-normal">
                            Walidacja Google Maps poprawna
                          </CBadge>
                      )}
                      {google_validation === false && (
                          <CBadge color={'danger'} className="white-space-normal">
                            Błędna walidacja Google Maps
                          </CBadge>
                      )}
                      {google_validation === undefined && <span>-</span>}
                    </>
                  }
              />
            </CCol>
            {customer_type_name === 'NATURAL_PERSON' && (
                <CCol sm="12" xl="12">
                  <DataRow fullHeight label="NIP pracodawcy" value={employer_nip} />
                </CCol>
            )}
          </CRow>
        </DataCard>
      </form>
    </>
  );
};

export default ClientBox;
